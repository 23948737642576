
import { defineComponent, onBeforeUnmount, ref } from 'vue';
import DownloadButton from '@/components/DownloadButton.vue';
import Illustration1 from '@/components/illustrations/DownloadOnboarding1.vue';
import Illustration2 from '@/components/illustrations/DownloadOnboarding2.vue';
import Illustration3 from '@/components/illustrations/DownloadOnboarding3.vue';
import Illustration4 from '@/components/illustrations/DownloadOnboarding4.vue';


const DownloadOnboardingView = defineComponent({
    components: { DownloadButton, Illustration1, Illustration2, Illustration3, Illustration4 },
    setup() {
        const index = ref(0);
        const interval = setInterval(() => {
            index.value == 3 ? index.value = 0 : index.value +=1;
        },5000);

        onBeforeUnmount(() => clearInterval(interval));
        
        return { index };
    }
});

export default DownloadOnboardingView;
