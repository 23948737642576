<template>
    <div class="h-screen">
        <Header>
            <div class="w-px h-6 bg-neutral-2"></div>
            <img src='https://www.iuds.org.br/redim/350x115/0/354/configs/9ad0f3cc0625b1753f18b813f727d413.jpg' class="h-10 px-6"/>
        </Header>

        <RouterView #default="{ Component }">
            <transition name="fade" mode="out-in">
                <component :is="Component"/>
            </transition>
        </RouterView>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';

const DownloadMainPage = defineComponent({
    components: { Header }
});

export default DownloadMainPage;
</script>

<style>

</style>