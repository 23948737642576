<template>
    <div class="h-screen flex flex-col items-center justify-center">
        <p class="text-3xl font-bold text-primary px-8 text-center mb-24">Clique no botão abaixo para iniciar o download do seu ambiente seguro</p>
        <div class="relative mb-40">
            <DownloadButton @pressed="$router.replace({ name: 'download-onboarding'})"
                            downloadUrl="https://superauladev.blob.core.windows.net/easyproctor-instalador/EasyProctorInstaller.exe?sp=r&st=2020-12-04T13:39:17Z&se=2022-12-04T21:39:17Z&spr=https&sv=2019-12-12&sr=b&sig=GfDsSSy3%2BSxrwj7BzbtMweBH5wp2RW%2BQXrEjSVq19%2B4%3D"/>
            <DownloadIllustration class="absolute w-44 " style="left: calc(100% + 35px); top: 20%"/>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import DownloadIllustration from '@/components/illustrations/DownloadIllustration.vue';
import DownloadButton from '@/components/DownloadButton.vue';

const DownloadView = defineComponent({
    components: { DownloadIllustration, DownloadButton }
});

export default DownloadView;
</script>

<style>

</style>