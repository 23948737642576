<template>
    <div class="h-screen">

        <div class="fixed top-20 left-1/2 transform -translate-x-2/4 flex">
            <div class="w-1 h-1 bg-gray-300 mx-1 rounded-full transition-colors duration-200" :class="{'bg-primary': index == 0}"></div>
            <div class="w-1 h-1 bg-gray-300 mx-1 rounded-full transition-colors duration-200" :class="{'bg-primary': index == 1}"></div>
            <div class="w-1 h-1 bg-gray-300 mx-1 rounded-full transition-colors duration-200" :class="{'bg-primary': index == 2}"></div>
            <div class="w-1 h-1 bg-gray-300 mx-1 rounded-full transition-colors duration-200" :class="{'bg-primary': index == 3}"></div>
        </div>
        <Transition name="fade" mode="out-in">

            <div class="h-screen flex flex-col items-center justify-center relative" v-if="index == 0" key="0">
                <p class="text-3xl font-bold text-primary px-8 text-center mb-24">Aguarde a finalização do download</p>
                <DownloadButton :loading="true"
                                downloadUrl="https://superauladev.blob.core.windows.net/easyproctor-instalador/EasyProctorInstaller.exe?sp=r&st=2020-12-04T13:39:17Z&se=2022-12-04T21:39:17Z&spr=https&sv=2019-12-12&sr=b&sig=GfDsSSy3%2BSxrwj7BzbtMweBH5wp2RW%2BQXrEjSVq19%2B4%3D"/>
                <Illustration1 class="absolute w-96 bottom-4 right-4"/>
            </div>

            <div class="h-screen flex flex-col items-center justify-center" v-else-if="index == 1" key="1">
                <p class="text-3xl font-bold text-primary px-8 text-center mb-24">Após finalizado o download,<br> clique no arquivo baixado para efetuar a instalação </p>
                <DownloadButton :loading="true"
                                downloadUrl="https://superauladev.blob.core.windows.net/easyproctor-instalador/EasyProctorInstaller.exe?sp=r&st=2020-12-04T13:39:17Z&se=2022-12-04T21:39:17Z&spr=https&sv=2019-12-12&sr=b&sig=GfDsSSy3%2BSxrwj7BzbtMweBH5wp2RW%2BQXrEjSVq19%2B4%3D"/>
                <Illustration2 class="absolute max-w-lg bottom-0 left-0"/>
            </div>

            <div class="h-screen flex flex-col items-center justify-center" v-else-if="index == 2" key="2">
                <p class="text-3xl font-bold text-primary px-8 text-center my-14">Aceite os termos de licença e condições<br>e clique em instalar</p>
                <img src="@/assets/img/app.jpg" style="height: 50vh"/>
                <Illustration3 class="absolute w-96 bottom-0 right-0"/>
            </div>

            <div class="h-screen flex flex-col items-center justify-center" v-else-if="index == 3" key="3">
                <p class="text-3xl font-bold text-primary px-8 text-center mb-24">Caso a instalação não finalize corretamente,<br>reinicie seu computador e tente novamente</p>
                <DownloadButton :loading="true"
                                downloadUrl="https://superauladev.blob.core.windows.net/easyproctor-instalador/EasyProctorInstaller.exe?sp=r&st=2020-12-04T13:39:17Z&se=2022-12-04T21:39:17Z&spr=https&sv=2019-12-12&sr=b&sig=GfDsSSy3%2BSxrwj7BzbtMweBH5wp2RW%2BQXrEjSVq19%2B4%3D"/>
                <Illustration4 class="absolute w-96 bottom-0 right-0"/>
            </div>
        </Transition>
    </div>
</template>

<script lang='ts'>
import { defineComponent, onBeforeUnmount, ref } from 'vue';
import DownloadButton from '@/components/DownloadButton.vue';
import Illustration1 from '@/components/illustrations/DownloadOnboarding1.vue';
import Illustration2 from '@/components/illustrations/DownloadOnboarding2.vue';
import Illustration3 from '@/components/illustrations/DownloadOnboarding3.vue';
import Illustration4 from '@/components/illustrations/DownloadOnboarding4.vue';


const DownloadOnboardingView = defineComponent({
    components: { DownloadButton, Illustration1, Illustration2, Illustration3, Illustration4 },
    setup() {
        const index = ref(0);
        const interval = setInterval(() => {
            index.value == 3 ? index.value = 0 : index.value +=1;
        },5000);

        onBeforeUnmount(() => clearInterval(interval));
        
        return { index };
    }
});

export default DownloadOnboardingView;
</script>

<style>

</style>