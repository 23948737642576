
import { defineComponent } from 'vue';
import DownloadIllustration from '@/components/illustrations/DownloadIllustration.vue';
import DownloadButton from '@/components/DownloadButton.vue';

const DownloadView = defineComponent({
    components: { DownloadIllustration, DownloadButton }
});

export default DownloadView;
