
import { defineComponent } from 'vue';

const DownloadButton = defineComponent({
    props: {
        downloadUrl: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
});

export default DownloadButton;
