<template>
    <a class="h-22 bg-white rounded-md shadow-lg flex overflow-hidden" :href="downloadUrl" download @click="$emit('pressed')">
        <div class="w-80 h-full relative">
            <!-- Informações -->
            <div class="w-full h-full px-6 flex items-center">
                <img src="@/assets/icons/times.svg" class="w-3"/>
                <img src="@/assets/icons/file-exe.svg" class="w-7 mx-6"/>
                <div>
                    <p class="text-primary text-xl font-bold leading-5">EasyProctor.exe</p>
                    <p class="opacity-25 leading-5 text-sm">78 mb</p>
                </div>
            </div>
            
            <!-- Animação -->
            <div class="absolute left-0 top-0 h-full bg-primary overflow-hidden w-0" :style="loading ? 'animation: loadingAnimation 2s infinite': ''">
                <div class="w-80 h-full px-6 flex items-center">
                    <img src="@/assets/icons/times.svg" class="w-3"/>
                    <img src="@/assets/icons/file-exe-white.svg" class="w-7 mx-6"/>
                    <div>
                        <p class="text-white text-xl font-bold leading-5">EasyProctor.exe</p>
                        <p class="text-white leading-5 text-sm">78 mb</p>
                    </div>
                </div>
            </div>

        </div>

        <div class="w-22 h-full bg-primary flex items-center justify-center">
            <img src="@/assets/icons/download.svg" class="w-7"/>
        </div>
    </a>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const DownloadButton = defineComponent({
    props: {
        downloadUrl: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
});

export default DownloadButton;
</script>

<style>
@keyframes loadingAnimation {
    0% {
        width: 0;
        opacity: 1;
    }
    80% {
        width: 100%;
        opacity: 1;
    }
    100%{
        width: 100%;
        opacity: 0;
    }

}
</style>